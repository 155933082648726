import "./App.css";
import "./responsive.css";
import React from "react";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import Home from "./Home";
import Faq from "./Faq";
import Tokenomics from "./Tokenomics";
import Launch from "./Launch";
import Login from "./Login";
import Mint from "./Mint";
import TermsandCondition from "./TermsandCondition";

export default function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route path="/tokenomics" element={<Tokenomics />} />
        <Route path="/faq" element={<Faq />} />
        <Route path="/launch" element={<Launch />} />
        <Route path="/" element={<Home />} />
        <Route path="/mint" element={<Mint />} />
        <Route path="/terms" element={<TermsandCondition />} />
      </Routes>
    </Router>
  );
}
