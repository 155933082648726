import { Container, Button, Image, Col, Row } from "react-bootstrap";
import "./App.css";
import Header from "./components/Header";
import Footer from "./components/Footer";

function Launch() {
  return (
    <div className="background_site height">
      <Container className="launching">
        <Header />
        <hr></hr>
        <div className="launching_content">LAUNCHING</div>
        <div className="launching_month">Q1 2022</div>
        <Button
          className="join-btn"
          href="https://discord.com/invite/c4B7NS7G5T"
        >
          {" "}
          JOIN THE WHITELIST
        </Button>

        <Footer />
      </Container>
    </div>
  );
}

export default Launch;
