import { Row, Col, Container, Button } from "react-bootstrap";

import ResponsivePlayer from "./videoPlayer.jsx";
import "./App.css";
import "./responsive.css";
import Header from "./components/Header";
import Footer from "./components/Footer";
// import ReactPlayer from "react-player";

function Home() {
  return (
    <div className="background_site">
      <Header />
      <div>
        <ResponsivePlayer />
        <br></br>
        <br></br>
        <Container className="content_section">
          <Row className="story_content_Mob">
            <Col md={2}>
              <div className="story text-right">
                {" "}
                <span> THE</span> <span className="inner_padding"></span>{" "}
                <span>STORY</span>
              </div>
            </Col>
            <Col className="story_content" md={10}>
              <p className="padding-top">
                It's the year 2066. Life as we know it:
              </p>
              <br></br>
              <p>
                <div>
                  Millennials are in wheelchairs, listening to the Backstreet
                  Boys in their nursing homes. Gen Z is busy doing Tik-Toks
                  about their midlife crisis.
                  <div>
                    However, this is all happening in a parallel digital
                    universe: the Metaverse.{" "}
                  </div>
                  <div>
                    The humans have entirely slipped into the metaverse, wildly
                    neglecting the physical world, which is on the brink of
                    extinction.
                  </div>
                </div>{" "}
                <br></br>
                <div>
                  An extraterrestrial civilization, watching this from space
                  wants to save the Earth before it's too late. They use their
                  powers to resurrect 3663 souls from their graves and transport
                  them into the metaverse using blockchain technology.
                </div>
                <br></br>
                <div>
                  Lucids - these magical semi-humans can save the world. But
                  they need to find a human mirror to be able to tap into their
                  power source. If you're a human with great vibes, your Lucid
                  will find you. And together, you save the planet!
                </div>
              </p>
              <br></br>
            </Col>
          </Row>
        </Container>
        <Container className="text-center">
          <a target="_blank" href="https://twitter.com/LucidsProject">
            <Button className="join-btn"> JOIN THE WHITELIST</Button>{" "}
          </a>
        </Container>
      </div>
      <Footer />
    </div>
  );
}

export default Home;
