import React , { useEffect, useRef } from 'react'
import './App.css';
import "./responsive.css";
import Desktopvideo from "../src/assets/Desktopvideo/Lucids_Desktop.mp4";
import Mobilevideo from "../src/assets/Mobilevideo/Lucids_Mobile.mp4";
import { Container, Row, Col } from 'react-bootstrap';



// function ResponsivePlayer() {
//   return (
//     <div className='video' >
//     <video  height="350" loop autoPlay={true}  controls >
//   <source src={video} type="video/mp4"></source>
//   <source src={video}  type="video/mp4"></source>
  
// </video>
//    </div>
//   )
// }
  
// export default ResponsivePlayer;

export default function ResponsivePlayer() {
  const videoEl = useRef(null);

  const attemptPlay = () => {
    videoEl &&
      videoEl.current &&
      videoEl.current.play().catch(error => {
        console.error("Error attempting to play", error);
      });
  };

  useEffect(() => {
    attemptPlay();
  }, []);

  return (
    <div className="App">
      <Container>
        <Row>
          
          <Col md={12}>
          <div className='video_desk'>
        <video 
          style={{ maxWidth: "100%", width: "100%",  }}
          playsInline
          loop
          muted
          // controls
          autoPlay
          alt="All the devices"
          src={Desktopvideo }
          ref={videoEl}
        />
          </div>
          <div className='video_mob'>
        <video 
          style={{ maxWidth: "100%", width: "100%",  }}
          playsInline
          loop
          muted
          // controls
          alt="All the devices"
          src={Mobilevideo }
          ref={videoEl}
        />
          </div>
          </Col>
        
          
          
     </Row>
     </Container>
    </div>
  );
}
