import {
  Row,
  Col,
  Container,
  Image,
  Button,
  InputGroup,
  FormControl,
  Modal,
} from "react-bootstrap";
import Swal from "sweetalert2";
// import { BigNumber } from "@ethersproject/bignumber";
import toy from "../src/assets/img/toy.gif";
import React, { useEffect, useState } from "react";
// import ResponsivePlayer from "./videoPlayer.jsx";
import "./App.css";
import "./responsive.css";
import Header from "./components/Header";
import Footer from "./components/Footer";
import * as Phantomwallet from "./Phantomwallet";
import * as Metawallet from "./Metamaskwallet";
import { messagePrefix } from "@ethersproject/hash";
import { BigNumber, ethers } from "ethers";
import Lucids from "../src/Lucids.json";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";



function Mint() {
  //--------------MetaMask Wallet------------------------//
  const contentId = "PINATA_CONTENT_ID";
  const metadataURI = `${contentId}.json`;
  // var BigNumber = require("big-number");

  const [errorMessage, setErrorMessage] = useState(null);
  const [defaultAccount, setDefaultAccount] = useState(null);
  const [userBalance, setUserBalance] = useState(null);
  const [connButtonText, setConnButtonText] = useState("Connect Wallet");
  const [Visible, setVisibleState] = useState(true);
  const [showConnect, setShowConnect] = useState(false);
  const [showDisconnect, setShowDisconnect] = useState(false);
  const [disable, setDisable] = React.useState(false);
  const [dialog, setDialog] = useState("Nothing");
  const [track, setTrack] = useState(0.066);
  let [count, setCount] = useState(1);
  const handleCloseConnect = () => setShowConnect(false);
  const handleShowConnect = () => setShowConnect(true);
  const handleCloseDisconnect = () => setShowDisconnect(false);
  const handleShowDisconnect = () => setShowDisconnect(true);
  const [account, setAccount] = useState({ balance: 0 });
  const time = null;
  const [ctime, setDate] = useState(time);
  const [userChainId, setChainID] = useState(null);
  const [userNetwork, setNetwork] = useState(null);

  const notify = () => toast("Minted Sucessfully");
  const notify1 = () => toast("Insufficient Funds");
  const notify2 = () => toast("Mint Error");
  //--------------To-be-Updated------------------------//
  function update_msg() {
    let timerInterval;
    Swal.fire({
      title: "Patience, Young Grasshopper.",
      html: "Check back on 7th April 2022",
      timer: 5000,
      timerProgressBar: true,
      didOpen: () => {
        Swal.showLoading();
        const b = Swal.getHtmlContainer().querySelector("b");
        // timerInterval = setInterval(() => {
        //   b.textContent = Swal.getTimerLeft()
        // }, 100)
      },
      willClose: () => {
        clearInterval(timerInterval);
      },
    }).then((result) => {
      /* Read more about handling dismissals below */
      if (result.dismiss === Swal.DismissReason.timer) {
        console.log("I was closed by the timer");
      }
    });
  }
  //--------------To-be-Updated------------------------//

  const getMintedStatus = async () => {
    console.log("metaDataUri:", metadataURI);
    console.log("contract:", contract);
    const result = await contract.isContentOwned(metadataURI);
    console.log("getMintedStatus:", result);
    // ? Should we use this?
    //setIsMinted(result);
  };

  /** 
   ** Mint Token Method
      @param 
  **/
  const mintToken = async () => {
    const contractAddress = "0x7fDC538356b1C137dC238001E92d480f197DEDeB";

const provider = new ethers.providers.Web3Provider(window.ethereum);

// get the end user
const signer = provider.getSigner();

// get the smart contract
const contract = new ethers.Contract(contractAddress, Lucids.result, signer);
    try {
      const connection = contract.connect(signer);
      const addr = connection.address;
      // console.log("track:", track);
      const totEth = track.toString();
      // console.log(userBalance);
      // console.log("totEth:", totEth, typeof totEth);
      if (userBalance >= totEth) {
        // console.log("I'm Inside v2");
        // const value = ethers.utils.parseEther(0.2);
        // const result = await contract.mintNFT(value.toString);

        const ethToMint = parseInt(count);
        // const ethToMint = count;
        // console.log("count", count);
        // console.log("ethToMint", ethToMint, typeof ethToMint);
        const ethPrice = ethToMint * 50;
        // console.log("ethPrice", ethPrice, typeof ethPrice);

        //const result = await contract.mintNFT(ethToMint, { value: ethPrice });
        const result = await contract.mint_multiple_with_nativecoin(3, {
          value: 1,
        });

        console.log("result:", result);
        var res = await result.wait();
        console.log("res:", res);
        // TODO: Check if this is needed once you get the original contract
        //getMintedStatus();
        notify();
      } else {
        notify1();
      }
    } catch (e) {
      console.log("error", e);
    }
  };
  async function getURI() {
    const uri = await contract.tokenURI(tokenId);
    alert(uri);
  }

  function incrementCount() {
    if (count < 2) {
      let track = 0.066;
      count = count + 1;
      let val = track * count;
      // console.log(val);
      setCount(count);
      setTrack(val);
    }
  }
  function decrementCount() {
    if(count>0){
    if (count >=1  && count <= 2);
    let track = 1.75;
    count = count - 1;
    let val = track * count;
    // console.log(val);
    setCount(count);
    setTrack(val);
  }}

  function refreshState() {
    window.localStorage.setItem("provider", undefined);
  }

  function ConnectedFailure() {
    setVisibleState(true);

    setDialog("false");
    setShowDisconnect(false);
    // window.solana.disconnect();
    // window.solana.on("Disconnect", () => console.log("disconnected!"));
    setDisable(false);
  }
  function Disconnect() {
    setShowDisconnect(false);
  }
  function Connect() {
    setShowConnect(false);
    setVisibleState(true);
  }
  function Chain_ID_check() {
    Swal.fire({
      icon: "error",
      title: "Invalid Wallet",
      text: "Change to ETH wallet in MetaMask",
    });
  }

  // //PHANTOM wallet Connect
  // const handleWallet=async(event)=>{
  // console.log("Connected");
  // const response= await Phantomwallet.connectWallet();
  //               setAccount({ ...account, balance: response });
  //       setDialog(true)
  //       setShowConnect(false);
  //       setDisable(true);;

  //   }
  // //PHANTOM wallet Refresh
  // const handleTime =async(e)=>{
  //   console.log("refreshed");
  //   const response= await Phantomwallet.handelTime();
  //   setDate(response)
  // }

  // META wallet connect
  const handleWallet = async () => {
    // const provider = new ethers.providers.Web3Provider(window.ethereum);
    // const network = await provider.getNetwork();
    // const chainId = network.chainId;
    // console.log(network.name);
    // console.log(chainId);
    if (true) {
      const response = await Metawallet.connectWallet();
      setConnButtonText("wallet Connected");
      setDefaultAccount(response);
      const bal = await Metawallet.getBalance(response);
      // console.log(network.name);
      // console.log(chainId);
      setUserBalance(bal);
      // setChainID(chainId);
      // setNetwork(network.name);
      setDialog("true");
      setShowConnect(false);
      setDisable("true");
      // console.log("Account :", response);
      // console.log("Balance :", bal);
      setVisibleState(false);
      // window.setInterval(handleWallet,1000);
    } else {
      Chain_ID_check();
    }
  };

  // META Wallet Refresh
  const handleTime = (e) => {
    let time = new Date().toLocaleTimeString();
    console.log("refreshed");
    Metawallet.getBalance(defaultAccount);
  };

  return (
    <div className="background_site">
      <Header />

      <div>
        <Container>
          <Row>
            <Col className="toys" md={6} sm={12} xs={12}>
              <Image
                src={
                  "https://static.wixstatic.com/media/c82d0d_06ee962fc9164bb6a25d1c6b27d41b0e~mv2.gif"
                }
              />
            </Col>
            <Col md={6} sm={12} xs={12}>
              <div className="form">
                <Row>
                  <div className="toys_mob">
                    <Image
                      src={
                        "https://static.wixstatic.com/media/c82d0d_06ee962fc9164bb6a25d1c6b27d41b0e~mv2.gif"
                      }
                    />
                  </div>
                </Row>

                <div className="form_text">
                  LET YOUR LUCID <br></br>
                  FIND YOU
                </div>
                <div className="form_content">Connect Wallet</div>
                <br></br>
                <div className="form_content_right">MINT PRICE</div>
                <div className="form_content_right2">[to-be-updated]</div>
                <div></div>
                <br></br>
                <div className="form_content_right">MINTED </div>
                <div className="form_content_right2">0/6666</div>
                <div></div>
                <br></br>
                <hr></hr>
                <div className="connect_btn">
                  <Button
                    disabled={disable}
                    className={Visible == true ? "connect" : "connect_hidden"}
                    onClick={handleWallet} /*{update_msg}*/
                  >
                    {" "}
                    Connect wallet
                  </Button>
                </div>

                <div className="error_message">
                  <div>
                    {dialog === "false" && (
                      <h5 className="text-danger">Disconnected</h5>
                    )}
                  </div>

                  <div>
                    {dialog === "true" && (
                      <div>
                        <h5 className="text-success">Wallet Connected</h5>
                        <br></br>
                        <Col className="disconnect_btn">
                          <Button
                            className="disconnect"
                            onClick={handleShowDisconnect}
                          >
                            Disconnect
                          </Button>
                        </Col>
                        <hr></hr>

                        <Row className="wallet_balance_head">
                          <h2>WALLET</h2>
                        </Row>
                        <br></br>
                        <Row className="wallet_balance_body">
                          <div>
                            <h3>
                              ADDRESS <span className="trans-text-val">:</span>
                              <span className="address_value">
                                {" "}
                                {defaultAccount}{" "}
                              </span>
                            </h3>
                          </div>
                          <div>
                            <h3>
                              BALANCE
                              <span className="trans-text-val">:</span>
                              <span className="bal_value">
                                {" "}
                                {/*account?.balance*/}
                                {userBalance} ETH{" "}
                              </span>
                              {/* <sub className="form_content">(@{ctime})</sub> */}
                            </h3>
                          </div>
                        </Row>
                        <Row className="wallet_balance_body">
                          {/* <h3>
                            CHAIN ID :{" "}
                            <span className="bal_value">{userChainId}</span>
                          </h3> */}
                        </Row>
                        <Row className="wallet_balance_body">
                          {/* <h3>
                            NETWORK :{" "}
                            <span className="bal_value">{userNetwork}</span>
                          </h3> */}
                        </Row>

                        <Row>
                          <Col>
                            {/* <Button onClick={handleTime} className="refresh">
                              Refresh
                            </Button> */}
                          </Col>
                          <Col></Col>
                        </Row>
                        <InputGroup className="mb-3">
                          <FormControl
                            value={count}
                            placeholder="ENTER THE AMOUNT OF LUCIDS YOU WANT TO PURCHASE"
                            aria-label="ENTER THE AMOUNT OF LUCIDS YOU WANT TO PURCHASE"
                          />
                          <InputGroup.Text
                            onClick={decrementCount}
                            className="minus"
                          >
                            -
                          </InputGroup.Text>
                          <InputGroup.Text
                            onClick={incrementCount}
                            className="minus"
                          >
                            +
                          </InputGroup.Text>
                        </InputGroup>
                        <div className="text_balance">
                          <Row>
                            <Col>
                              <h4 className="trans-text">
                                TRANSACTION{" "}
                                <span className="trans-text-val">:</span>
                                <span className="track-value">
                                  {" "}
                                  {track} ETH
                                </span>{" "}
                              </h4>{" "}
                            </Col>
                          </Row>
                          <br></br>
                        </div>
                        <Col className="mint_btn_padding">
                          <Button onClick={mintToken} className="mint-btn">
                            MINT
                          </Button>
                        </Col>
                        <ToastContainer
                          type="success"
                          theme="dark"
                          position="bottom-center"
                          autoClose={3000}
                          hideProgressBar={false}
                          newestOnTop={false}
                          closeOnClick
                          rtl={false}
                          pauseOnFocusLoss={false}
                          draggable={false}
                          pauseOnHover={false}
                        />
                      </div>
                    )}
                  </div>
                </div>
                {/* <small>
                  <p>
                    For NFT newbies, note that only 1 NFT can be minted per
                    transaction. A maximum of 5 NFTs can be minted per wallet.
                  </p>
                </small> */}
              </div>
            </Col>
          </Row>
          <br></br>
        </Container>{" "}
        {/* Conncect Modal */}
        {/* <Modal show={showConnect} onHide={handleCloseConnect}>
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>Would you like to connect to the Wallet?</Modal.Body>
          <Modal.Footer>
            <Button variant="success" onClick={handleWallet}>
              Yes
            </Button>
            <Button variant="danger" onClick={Connect}>
              No
            </Button>
          </Modal.Footer>
        </Modal> */}
        <Modal show={showDisconnect} onHide={handleCloseDisconnect}>
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>Would you like to disconnect from the Wallet?</Modal.Body>
          <Modal.Footer>
            <Button variant="success" onClick={ConnectedFailure}>
              Yes
            </Button>
            <Button variant="danger" onClick={Disconnect}>
              No
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
      <Footer />
    </div>
  );
}

export default Mint;
