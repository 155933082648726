import { ethers } from "ethers";
import Swal from "sweetalert2";
import "./App.css";

const connectWallet = async () => {
  return new Promise((resolve, reject) => {
    if (window.ethereum && window.ethereum.isMetaMask) {
      window.ethereum
        .request({ method: "eth_requestAccounts" })
        .then((result) => {
          console.log("Wallet-Connected");
          resolve(result[0]);
        })
        .catch((error) => {
          reject(error);
          return error.message;
        });
    } else {
      console.log("Need to install MetaMask");

      Swal.fire({
        icon: "error",
        title: "Wallet not Found",
        html: '<a target="_blank" href="https://metamask.io/download/">Click here to download MetaMask</a> ',
      });
    }
  });
};

const accountChangedHandler = (newAccount) => {
  getBalance(newAccount.toString());
};

let getBalance = async (account) => {
  return new Promise((resolve, reject) => {
    const acc = window.ethereum
      .request({ method: "eth_getBalance", params: [account, "latest"] })
      .then((balance) => {
        console.log("Balance", ethers.utils.formatEther(balance));
        let fmtBalance = ethers.utils.formatEther(balance);
        resolve(fmtBalance);
      })
      .catch((error) => {
        console.log(error, "err");
        reject(error);
      });
  });
};

const chainChangedHandler = () => {
  // reload the page to avoid any errors with chain change mid use of application
  window.location.reload();
};
// listen for account changes
// window.ethereum.on("accountsChanged", accountChangedHandler);

// window.ethereum.on("chainChanged", chainChangedHandler);

export { connectWallet, getBalance };
