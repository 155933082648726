import { Row, Col, Container, Image, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import React from "react";
import "../App.css";
import "../responsive.css";
/**Images import */
import logo from "../../src/assets/img/logo.png";
import twitter from "../../src/assets/img/twitter.png";
import discord from "../../src/assets/img/discord.png";
import instagram from "../../src/assets/img/instagram.png";

class Header extends React.Component {
  render() {
    return (
      <>
        <Container className="container_header">
          <Row>
            <Col className="list_road" md={2} sm={6} xs={12}>
              <Link to="/tokenomics">
                <br></br>
                <br></br>TOKENOMICS
              </Link>
            </Col>

            <Col className="list_faq" md={2} sm={6} xs={12}>
              <Link to="/faq">
                <br></br>
                <br></br>
                FAQ
              </Link>
            </Col>

            <Col className="list_logo" md={4} sm={6} xs={12}>
              <Link to="/">
                <Image className="logo" src={logo} alt="Lucids Logo.png" />
              </Link>
            </Col>

            <Col className="list" md={2} sm={6} xs={12}>
              <Link to="/mint">
                <Button className="mint-btn-header">MINT</Button>
              </Link>
              {/* <a
                target="_blank"
                href="https://drive.google.com/file/d/19AFkZ--kh0qTob9YbEFEpfnNOojAGHoq/view?usp=sharing"
              >
                <Button className="mint-btn-header">LITEPAPER</Button>
              </a> */}
            </Col>
            <Col className="list_icons" md={2} sm={6} xs={4}>
              <ul className="list-inline">
                <li className="list-inline-item">
                  <a
                    target="_blank"
                    href="https://www.twitter.com/lucidsproject"
                  >
                    {" "}
                    <Image src={twitter} alt="twitter" />
                  </a>
                </li>
                <li className="list-inline-item">
                  <a
                    target="_blank"
                    href="https://www.instagram.com/lucidsproject"
                  >
                    {" "}
                    <Image src={instagram} alt="instagram" />
                  </a>
                </li>
                <li className="list-inline-item">
                  <a target="_blank" href="https://discord.gg/lucids">
                    <Image src={discord} alt="discord" />
                  </a>
                </li>
              </ul>
            </Col>
            <Col className="list_logo_Tablet_view" md={12} sm={6} xs={12}>
              <Link to="/">
                <Image className="logo" src={logo} alt="Lucids Logo.png" />
              </Link>
            </Col>
            <div className="list_mobile_view">
              <Col md={12} sm={12} xs={12}>
                <Link to="/mint">
                  <Button className="mint-btn-header_tablet_view">
                    MINT
                  </Button>
                </Link>
                {/* <a
                  target="_blank"
                  href="https://drive.google.com/file/d/19AFkZ--kh0qTob9YbEFEpfnNOojAGHoq/view?usp=sharing"
                >
                  <Button className="mint-btn-header_tablet_view">
                    LITEPAPER
                  </Button>
                </a> */}
              </Col>
            </div>

            <Col className="list_road_mobile_view" md={2} sm={6} xs={4}>
              <Link to="/tokenomics">
                <br></br>
                <br></br>TOKENOMICS
              </Link>
            </Col>
            <Col className="list_road_tablet_view" md={4} sm={4} xs={4}>
              <br></br>
              <Link to="/tokenomics">TOKENOMICS</Link>
            </Col>

            <Col className="list_tab_view" md={4} sm={4} xs={5}>
              <ul className="list-inline">
                <li className="list-inline-item">
                  <a
                    target="_blank"
                    href="https://www.twitter.com/lucidsproject"
                  >
                    {" "}
                    <Image src={twitter} alt="twitter" />
                  </a>
                </li>
                <li className="list-inline-item">
                  <a
                    target="_blank"
                    href="https://www.instagram.com/lucidsproject"
                  >
                    {" "}
                    <Image src={instagram} alt="instagram" />
                  </a>
                </li>
                <li className="list-inline-item">
                  <a target="_blank" href="https://discord.gg/lucids">
                    <Image src={discord} alt="discord" />
                  </a>
                </li>
              </ul>
            </Col>
            <Col className="list_faq_mobile-view" md={2} sm={6} xs={3}>
              <Link to="/faq">FAQ</Link>
            </Col>
            <Col className="list_faq_tablet-view" md={4} sm={4} xs={4}>
              <br></br>
              <Link to="/faq">FAQ</Link>
            </Col>

            <Col className="list" md={2} sm={6} xs={12}></Col>
          </Row>
          <hr />
        </Container>
      </>
    );
  }
}
export default Header;
