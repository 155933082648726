import { Container, Row, Col } from "react-bootstrap";
import "./App.css";
import Footer from "./components/Footer";
import Header from "./components/Header";
import "./responsive.css";
function Faq() {
  return (
    <div className="background_site">
      <Header />

      <Container className="faq_question">
        <Row className="faq_mob">
          <h2 className="form_text">YOUR QUESTIONS ANSWERED</h2>

          <Col>
            <br></br>
            <br></br>
            <h5> 1. What is the collection size of Lucids?</h5>
            <p>
              Lucids collection has 3663 unique Non-Fungible Tokens on Solana
              blockchain.
            </p>
            <br></br>
            <h5> 2. How many traits are present? </h5>
            <p>
              Lucids NFTs are algorithmically generated based on 150 hand-drawn
              traits like facial features, clothing, and accessories.
            </p>
            <br></br>
            <h5> 3. When and where can I mint Lucids NFTs?</h5>
            <p className="faq_link">
              You can mint Lucids NFTs at{" "}
              <a target="_blank" href="https://www.lucids.io/mint">
                https://www.lucids.io/mint
              </a>
              <br></br>
              Minting goes live in Q2 2022. The exact date and time will be
              announced on our Twitter and Discord.
            </p>
            <br></br>
            <h5> 4. How much does it cost to mint?</h5>
            <p>It costs 1.75 SOL to mint a Lucids NFT.</p>
            <br></br>

            <h5> 5. How many Lucids NFTs can I mint?​</h5>
            <p>You can mint a maximum of 3 Lucids NFTs per wallet</p>

            <br></br>

            <h5> 6. Are there any special characters in the collection? </h5>
            <p>
              Each Lucid is unique with its own set of traits! However, there
              are two categories of Lucids that are more special than the rest:{" "}
            </p>
            <p>
              <span className="faq_sub">Locked Lucids</span> - 33 Lucids NFTs
              are locked away for a period of 100 days to 6 months. After the
              lock period, these Lucids are sent to our most loyal supporters,
              community members, and contest winners. One of these Locked Lucids
              holds the key to the future of all 6666 Lucids.
            </p>
            <p>
              <span className="faq_sub">Cha-Ching Lucids</span> - These are the
              rich bois who were allowed to carry some pocket change across the
              virtual realm. They are characterized by a fancy eyewear, flashing
              their $$$. First owners of Cha-Ching Lucids are also entitled to
              their riches. This means, if you mint a Cha-Ching Lucid on our
              website, you will be airdropped the $$$ figure mentioned on their
              eyewear.
            </p>
            <br></br>
            <h5>
              7. Is the minting open for the public or is there a whitelist?
            </h5>
            <p>
              Yes, there is a whitelist. Whitelisted members will have priority
              access to mint their Lucids NFTs.
            </p>
            <br></br>

            <h5>8. How can I get a whitelist spot?​ </h5>
            <p className="faq_link">
              We have a whole system put in place to award whitelists to the
              most deserving community members. Follow our Twitter for more
              details.
            </p>
            <br></br>
            <h5>9. What can I do with Lucids NFTs?</h5>
            <p>
              <span className="faq_sub">NFT Sale = Token Sale : </span>Investing
              in Lucids NFTs is equivalent to investing in our token pre-sale.
              Holding an NFT is the only way to get Lucids tokens before launch
              which will be used in the P2E game economy. There is no separate
              private sale or pre-sale for the token. Restricting the initial
              token supply only to NFT holders is bound to bring the best
              returns for our investors.
              <br></br>
              <br></br>
              <span className="faq_sub">Passive income : </span>Profits from
              Lucids Creators Fund are distributed to diamond hand-holders as
              rewards.
              <br></br>
              <br></br>
              <span className="faq_sub">Commercial rights : </span>When you buy
              a Non-Fungible Token from Lucids digital collection, you own the
              artwork and all the commercial rights associated with it. This
              includes a royalty-free license to use, copy, and display the
              purchased art for the purpose of creating derivative works based
              upon the art, for example, such as the use of the art to produce
              and sell merchandise products, display copies, and earn revenue
              from any of the foregoing. Feel free to use this license to get
              the most out of your purchase. However, secondary sales of Lucids
              NFTs on third-party marketplaces will attract a royalty of 2.5%.
              <br></br>
              <br></br>
              <span className="faq_sub">Club exclusive : </span>Lucids NFT is
              your digital identity, your avatar, and your access card to an
              exclusive club of like-minded crypto investors and holders-only
              perks in the future.
            </p>
            <br></br>

            <h5>10. How can I get in touch with the team?</h5>
            <p className="faq_link_white">
              The project is conceptualized and executed by an all-girls team at
              Pupa Labs. We are a group of digital artists and designers who
              came together to create something unique in a space plagued by
              derivatives. Most of us have been in the crypto space since late
              2020. You can reach us at{" "}
              <a target="_blank" href="https://www.pupalabs.com/">
                hello@pupalabs.com.
              </a>
            </p>
            <br></br>
          </Col>
        </Row>
      </Container>

      <Footer />
    </div>
  );
}
export default Faq;
