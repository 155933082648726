import { Container, Row, Col } from "react-bootstrap";
import "./App.css";
import Footer from "./components/Footer";
import Header from "./components/Header";
import "./responsive.css";
function TermsandCondition() {
  return (
    <div className="background_site">
      <Header />

      <Container className="terms_text">
        <Row>
          <h2>TERMS & CONDITIONS</h2>

          <Col>
            <br></br>
            <p>Last updated on March 04, 2022 </p>
            <br></br>
            <p>
              Lucids is a collection of Non-Fungible Tokens running on the
              Ethereum network. This website www.lucids.io only offers the
              interface for the purchase of Non-Fungible Tokens from Lucids
              digital collection. This website and its connected services are
              provided “as is” and “as available” without a warranty of any
              kind. By using this website you agree to all of the terms and
              conditions listed herein. If you do not agree to these terms and
              conditions, you may not participate in any of the services offered
              on this website.{" "}
            </p>
            <h5> 1. COMMERCIAL RIGHTS</h5>
            <p>
              Each digital artwork purchased on this website is a Non-Fungible
              Token, part of Lucids digital collectibles.<br></br>
              When you buy a Non-Fungible Token from Lucids digital collection,
              you own the artwork and all the commercial rights associated with
              it. This includes a royalty-free license to use, copy, and display
              the purchased art for the purpose of creating derivative works
              based upon the art, for example, such as the use of the art to
              produce and sell merchandise products, display copies, and earn
              revenue from any of the foregoing.
              <p>
                <br></br>
                The use of your licensed Non-Fungible Tokens directly or
                indirectly, in connection with images, videos, or other forms of
                media that depict hatred, intolerance, violence, cruelty, or
                anything else that is deemed illegal is strictly prohibited. Any
                issues arising out of such cases shall be the sole
                responsibility of the owner of the Non-Fungible Token in
                question.{" "}
              </p>
            </p>
            <br></br>
            <h5> 2. SECURITY </h5>
            <p>
              All Ethereum transactions on this website, related to owning a
              Non-Fungible Token are mediated by a smart contract. All
              transactions happening between you and the smart contract are
              irreversible. It is solely your responsibility to validate any
              transaction generated on this website before approval. You are
              solely responsible for the safety and management of your Ethereum
              wallet which you connect to this site for the purchase of Lucids
              Non-Fungible Tokens.
            </p>
            <br></br>

            <h5> 3. ASSUMPTION OF RISK</h5>
            <p>
              By purchasing a Non-Fungible Token from Lucids digital collection,
              you agree that you assume the following risks:
              <br></br>
              3.1. To the extent there is a price or market for a blockchain
              asset such as a Non-Fungible Token, such markets and prices are
              extremely volatile, and variations in the price of other digital
              assets could materially and adversely affect the value of any
              digital assets you own, including your Lucids Non-Fungible Token.
              3.2. There are risks associated with using Internet-native assets
              including, but not limited to, the risk of hardware, software, and
              internet connections and/or failures, the risk of malicious
              software introduction, and the risk that third parties may obtain
              unauthorized access to information stored within your digital
              “wallet” or elsewhere, and any such event shall be solely your
              responsibility. 3.3. The risk of changes to the regulatory regime
              governing blockchain technologies, cryptocurrencies, and tokens
              and new regulations, unfavorable regulatory intervention in one or
              more jurisdictions or policies any of which may materially
              adversely affect the use and value of your Lucids Non-Fungible
              Token.<br></br>
              3.4. The risks related to taxation of Non-Fungible Tokens.
              <p>
                <br></br>
                In addition to assuming all of the above risks, you acknowledge
                that you have obtained sufficient information to make an
                informed decision to purchase and use the licensed Lucids
                Non-Fungible Token and that you understand and agree that you
                are solely responsible for determining the nature, potential
                value, suitability, and appropriateness of these risks for
                yourself.
              </p>
              <p className="faq_link_white">
                If you have any questions or concerns, we encourage you to
                contact us via e-mail at{" "}
                <a href="mailto:hello@pupalabs.com" target="_self">
                  hello@pupalabs.com.
                </a>
              </p>
            </p>
            <br></br>
          </Col>
        </Row>
      </Container>

      <Footer />
    </div>
  );
}
export default TermsandCondition;
