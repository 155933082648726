import { Row, Col, Container, Image, Button } from "react-bootstrap";
import "./App.css";
import "./responsive.css";
import Header from "./components/Header";
import Footer from "./components/Footer";

function Tokenomics() {
  return (
    <div className="background_site">
      <Header />
      <Container>
        <Row>
          <Col className="toys" md={6} sm={12} xs={12}>
            <Image
              src={
                "https://static.wixstatic.com/media/c82d0d_06ee962fc9164bb6a25d1c6b27d41b0e~mv2.gif"
              }
            />
          </Col>
          <Col md={6} sm={12} xs={12}>
            <div className="form">
              <Row>
                <div className="toys_mob">
                  <Image
                    src={
                      "https://static.wixstatic.com/media/c82d0d_06ee962fc9164bb6a25d1c6b27d41b0e~mv2.gif"
                    }
                  />
                </div>
              </Row>

              <div className="form_text_token">TOKENOMICS</div>
              <div className="form_content_token">
                CHAIN : <span className="form_content_token_right">SOLANA</span>
              </div>
              <br></br>
              <div className="form_content_token">
                NFT COLLECTION SIZE :{" "}
                <span className="form_content_token_right">3663</span>
              </div>
              <br></br>
              <div className="form_content_token">
                TOKEN SUPPLY :{" "}
                <span className="form_content_token_right">50,000,000</span>
              </div>
              <br></br>
              <div className="form_content_token">
                OG SALE :{" "}
                <div className="form_content_token_right">
                  13,320 tokens/ 1.75 SOL
                </div>
              </div>
              <br></br>
              <div className="form_content_token">
                WHITELISTED AND PUBLIC SALE :{" "}
                <div className="form_content_token_right">
                  8800 tokens/ 1.75 SOL
                </div>
              </div>
              <br></br>
              <div className="form_content_token">
                TAXES :{" "}
                <div className="form_content_token_right">9% BUY | 9% SELL</div>
                <br></br>
                <div className="form_content_token_right">1.5% Liquidity </div>
                <div className="form_content_token_right">1.5% Reflections</div>
                <div className="form_content_token_right">
                  2% Project Development
                </div>
                <div className="form_content_token_right">2% Marketing</div>
                <div className="form_content_token_right">2% Team</div>
              </div>
              <br></br>
            </div>
          </Col>
        </Row>
        <br></br>
      </Container>
      {/* <Container className="content_section road_map">
        <Row className="road_map_mob">
          <Col md={2}>
            <div className="road text-right">
              {" "}
              <span>
                {" "}
                PROJECT{" "}
              </span> <span className="inner_padding"></span>{" "}
              <span>ROADMAP</span>
            </div>
          </Col>
          <Col md={4}>
            <br></br>
            <h5>PHASE 1​</h5>
            <div className="road_list">
              <ul>
                <li>Roll out our post-launch marketing plans</li>
                <li>
                  Regular collabs with Key Opinion Leaders in the NFT space
                </li>
                <li>
                  Align marketing activities to make Lucids a valuable,
                  profitable, long term crypto asset
                </li>
                <li>
                  Publish Lucids rarity rank and update the same across rarity
                  ranking websites
                </li>
                <li>
                  Launch Lucids Lounge - A digital lounge for Lucids owners to
                  hang out and chill. This space will host weekly activities and
                  events arranged exclusively for Lucids NFT holders
                </li>
              </ul>
            </div>
            <h5> PHASE 2 ​</h5>
            <div className="road_list">
              <ul>
                <li>Unleash Locked Lucids</li>
                <li>Art collaborations with artists in the Lucids community</li>
                <li>Free limited-edition NFT airdrop for Lucids holders</li>
                <li>Continued marketing push</li>
                <li>
                  Utility development - Explore avenues for the expansion of
                  Lucids metaverse
                </li>
              </ul>
            </div>
            <h5> PHASE 3 ​</h5>
            <div className="road_list">
              <ul>
                <li>Official merch store goes live</li>
                <li>Merch collabs with your favorite NFT influencers</li>
                <li>Utility launch - Lucids metaverse evolves and expands</li>
              </ul>
            </div>
          </Col>
          <Col md={2}>
            <div className="road text-right">
              {" "}
              <span> LAUNCH </span> <span className="inner_padding"></span>{" "}
              <span>ROADMAP</span>
            </div>
          </Col>
          <Col md={4}>
            <br></br>
            <h5>2222 MINTED: THEY'RE LOVIN' IT! ​</h5>
            <p>
              The Lucids are amused by the metaverse. They now understand why
              the mere mortals refuse to go back to the physical world.
            </p>
            <h5>3333 MINTED: BFF REUNION!​</h5>
            <p>
              Some Lucids are homesick! They miss their BFFs! So we've decided
              to send 33 Lucids companions to some lucky Lucids. If you've
              minted before the 3333 mark, your wallet is auto-enrolled for a
              lucky draw for a free Lucids NFT airdrop.
            </p>
            <h5> 4444 MINTED: AND THEY HAVE FAVOURITES!​</h5>
            <p>
              The Lucids have spotted 11 human mirrors worthy enough to travel
              with them in the future. These 11 lucky ones gain priority access
              to all our product rollouts.
            </p>
            <h5>5555 MINTED: UMMM, ARE WE LOST?</h5>
            <p>
              2 Lucids with super rare powers have lost their human mirrors'
              addresses. We know where they might just be! Check your wallets to
              know if you’re the lucky ones!
            </p>
            <h5>6666 MINTED: THE GRAND PLAN</h5>
            <p>
              All Lucids have successfully found their human mirrors. They can
              now tap into their source of magic and unleash their powers to
              save the planet.
            </p>
          </Col>
        </Row>
        <br></br>
      </Container> */}
      <br></br>
      <Footer />
    </div>
  );
}

export default Tokenomics;
