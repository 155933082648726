import React from "react";
import { Row, Col, Container, Image } from "react-bootstrap";
import pupa from "../../src/assets/img/Pupa Labs Logo.png";
import { Link } from "react-router-dom";
import "../App.css";
class Footer extends React.Component {
  render() {
    return (
      <>
        {" "}
        <Container>
          <hr></hr>
          <Row>
            <Col md={1} sm={1} xs={0}></Col>
            <Col md={10} sm={10} xs={12}>
              <div className="important-content">
                <span className="content_footer_text">IMPORTANT :</span> We will
                never send you unsolicited DMs. Stay alert and don’t fall for
                scams. Our only official communication channels are: Telegram:{" "}
                <span className="content_footer_text">@pupalabs</span> |
                Twitter:{" "}
                <span className="content_footer_text">
                  <a
                    target="_blank"
                    href="https://www.twitter.com/lucidsproject"
                  >
                    @lucidsPROJECT
                  </a>{" "}
                </span>
                | Instagram:{" "}
                <span className="content_footer_text">
                  <a
                    target="_blank"
                    href="https://www.instagram.com/lucidsproject"
                  >
                    @lucidsPROJECT
                  </a>
                </span>{" "}
                | Discord:{" "}
                <span className="content_footer_text">
                  Private. Follow our Twitter for an invite
                </span>{" "}
                | E-mail:
                <a
                  href="mailto:hello@pupalabs.com"
                  target="_self"
                  className="content_footer_text"
                >
                  {" "}
                  hello@pupalabs.com
                </a>
              </div>
            </Col>

            <Col md={1} sm={1} xs={0}></Col>
          </Row>
          <br></br>
          <Row className="footer-txt">
            <Col md={3}>
              {" "}
              <Row>
                <Col>
                  <Link className="footer_test" to="/terms">
                    <div className="footer_terms">
                      <a href="/"> Terms and Conditions </a>
                    </div>
                  </Link>
                </Col>
                {/* <Col md={5}>
                  <div className="footer_rarity">
                    <a target="_blank" href="https://raritysniper.com/">
                      {" "}
                      NFT Rarity
                    </a>
                  </div>
                </Col> */}
              </Row>
            </Col>

            <Col className="footer second_footer" md={6}>
              <span className="footer_txt_center">
                {" "}
                Conceptualised and executed by{" "}
              </span>

              <a
                className="pupa_txt"
                target="_blank"
                href="https://www.pupalabs.com/"
              >
                PUPA LABS
              </a>
              <br></br>
            </Col>
            <Col className="footer_lucids" md={3}>
              <p>Lucids.io {new Date().getFullYear()}</p>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}
export default Footer;
